import {
  Text,
  SegmentedControl,
  Container,
  Center,
} from "@mantine/core";
import ReactCountryFlag from "react-country-flag";
import { MyLanguages } from "../../i18n";
import { useLanguage } from '../../LanguageContext';

export function LanguagePickerMobile() {
  const { selectedLanguage, changeLanguage } = useLanguage();

  const segmentedControlData = MyLanguages.map((language) => ({
    label: (
      <Container>
        <ReactCountryFlag countryCode={language.code} svg />
        <Text>{language.label}</Text>
      </Container>
    ),
    value: language.code,
  }));
  return (
    <Center>
      <SegmentedControl
        value={selectedLanguage.code}
        onChange={(newValue) => {
          const selectedLang = MyLanguages.find(lang => lang.code === newValue);
          changeLanguage(selectedLang || MyLanguages[0]); // Fallback to first language if selected language not found
        }}
        data={segmentedControlData}
      />
    </Center>
  );
}
