
import { useEffect, useState } from "react";
import { ImageHero } from "../ImageHero/ImageHero";

export function HeroTitle() {
  const [, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div><ImageHero /></div>;
}
