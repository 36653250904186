import { Center, SimpleGrid, Space, useMantineTheme } from "@mantine/core";
import {
  IconAppWindow,
  IconPlayerTrackNext,
  IconEyeExclamation,
  IconSettingsAutomation,
  IconFlagExclamation,
  IconVideo,
  IconAdjustments,
  IconArrowBackUp,
} from "@tabler/icons-react";
import { FeatureCard } from "../FeatureCard/FeatureCard";
import accTV1 from "../../media/CameraControl_1.webp";
import accTV2 from "../../media/Autopilot.png";
import accTV3 from "../../media/AdvancedAnalysis.png";
import accTV4 from "../../media/Highlights.png";
import accTV5 from "../../media/Intros_1.webp";
import accTV6 from "../../media/CustomUI2.webp";
import accTV7 from "../../media/Gridwalk_1.webp";
import accTV8 from "../../media/Incidents2.png";
import I18n from "../../i18n.js";

export const featureList = [
  {
    id: "feature1",
    title: "features.feature1.title",
    subtitle: "features.feature1.subtitle",
    description: "features.feature1.description",
    modal: "features.feature1.modalText",
    icon: IconVideo,
    image: accTV1
  },
  {
    title: "features.feature2.title",
    subtitle: "features.feature2.subtitle",
    description: "features.feature2.description",
    modal: "features.feature2.modalText",
    icon: IconSettingsAutomation,
    image: accTV2
  },
  {
    title: "features.feature3.title",
    subtitle: "features.feature3.subtitle",
    description: "features.feature3.description",
    modal: "features.feature3.modalText",
    icon: IconEyeExclamation,
    image: accTV3
  },
  {
    title: "features.feature4.title",
    subtitle: "features.feature4.subtitle",
    description: "features.feature4.description",
    modal: "features.feature4.modalText",
    icon: IconArrowBackUp,
    image: accTV4
  },
  {
    title: "features.feature5.title",
    subtitle: "features.feature5.subtitle",
    description: "features.feature5.description",
    modal: "features.feature5.modalText",
    icon: IconPlayerTrackNext,
    image: accTV5
  },
  {
    title: "features.feature6.title",
    subtitle: "features.feature6.subtitle",
    description: "features.feature6.description",
    modal: "features.feature6.modalText",
    icon: IconAdjustments,
    image: accTV6
  },
  {
    title: "features.feature7.title",
    subtitle: "features.feature7.subtitle",
    description: "features.feature7.description",
    modal: "features.feature7.modalText",
    icon: IconAppWindow,
    image: accTV7
  },
  {
    title: "features.feature8.title",
    subtitle: "features.feature8.subtitle",
    description: "features.feature8.description",
    modal: "features.feature8.modalText",
    icon: IconFlagExclamation,
    image: accTV8
  },
];

export const dynamicFeatureList = {
  title: "features.<id>.title",
  subtitle: "features.<id>.subtitle",
  description: "features.<id>.description",
  modal: "features.<id>.modalText",
  features: [
    {
      id: "feature1",
      icon: IconVideo,
      image: accTV1
    },
    {
      id: "feature2",
      icon: IconSettingsAutomation,
      image: accTV2
    },
    {
      id: "feature3",
      icon: IconEyeExclamation,
      image: accTV3
    },
    {
      id: "feature4",
      icon: IconArrowBackUp,
      image: accTV4
    },
    {
      id: "feature5",
      icon: IconPlayerTrackNext,
      image: accTV5
    },
    {
      id: "feature6",
      icon: IconAdjustments,
      image: accTV6
    },
    {
      id: "feature7",
      icon: IconAppWindow,
      image: accTV7
    },
    {
      id: "feature8",
      icon: IconFlagExclamation,
      image: accTV8
    },
]
};

export function FeatureCardList() {
  const theme = useMantineTheme();
  const featureCards = featureList.map((feature, index) => {
    return <Center
        key={index}>
        <FeatureCard
          featureName={I18n.t(feature.title)}
          featureText={I18n.t(feature.subtitle)}
          longText={I18n.t(feature.description)}
          modalText={I18n.t(feature.modal)}
          icon={feature.icon}
          image={feature.image}
        />
      </Center>
  });
  const dynamicFeatureCards = dynamicFeatureList.features.map((feature, index) => {
    return <Center id={feature.id}
        key={index}>
        <FeatureCard
          featureName={I18n.t(dynamicFeatureList.title.replace("<id>", feature.id))}
          featureText={I18n.t(dynamicFeatureList.subtitle.replace("<id>", feature.id))}
          longText={I18n.t(dynamicFeatureList.description.replace("<id>", feature.id))}
          modalText={I18n.t(dynamicFeatureList.modal.replace("<id>", feature.id))}
          icon={feature.icon}
          image={feature.image}
        />
      </Center>
  });
  return (
    <Center>
      <SimpleGrid
        cols={{ base: 1, sm: 2, xl: 4 }}
        spacing="xs"
        verticalSpacing="xl" style={{ maxWidth: theme.breakpoints.xl, width: '100%' }}>
        {dynamicFeatureCards}
      </SimpleGrid>
    </Center>
  );
}
