import "./App.css";
import "@mantine/core/styles.css";
import {
  AppShell,
  Box,
  Burger,
  Center,
  Collapse,
  Divider,
  Drawer,
  Group,
  HoverCard,
  ScrollArea,
  UnstyledButton,
  rem,
  Text,
  ThemeIcon,
  SimpleGrid,
} from "@mantine/core";
import { useTranslation } from 'react-i18next';
import { useEffect  } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Logo from "./components/Logo/Logo";
import { useDisclosure } from "@mantine/hooks";
import { Link, animateScroll as scroll, scroller } from 'react-scroll';
import { LanguagePicker } from "./components/LanguagePicker/LanguagePicker";
import {
  IconChevronDown,
} from "@tabler/icons-react";
import classes from "./App.module.css";
import { LanguagePickerMobile } from "./components/LanguagePickerMobile/LanguagePickerMobile";
import BodyContent from "./components/BodyContent/BodyContent";
import { LanguageProvider } from './LanguageContext';
import { dynamicFeatureList } from "./components/FeatureCardList/FeatureCardList";
const scrollToAnchor = (id: string) => {
  scroller.scrollTo(id, {
    duration: 500,
    delay: 0,
    offset: -64, // Offset in Pixel, um den Header zu berücksichtigen
  });
};
function App() {
  useTranslation();
  const [opened] = useDisclosure();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const links = dynamicFeatureList.features.map((feature) => (
    <a
      onClick={() => { scrollToAnchor(feature.id); closeDrawer(); }} 
      key={feature.id}>
      <UnstyledButton className={classes.subLink} key={feature.id}>
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <feature.icon
              style={{ width: rem(22), height: rem(22) }}
              color={"#FF7F2A"}
            />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {i18n.t(dynamicFeatureList.title.replace("<id>", feature.id))}
            </Text>
            <Text size="xs" c="dimmed">
              {i18n.t(dynamicFeatureList.description.replace("<id>", feature.id))}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </a>
  ));
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  });
  return (
    <LanguageProvider>
      <div className="App">
        <AppShell id="home"
          header={{ height: 60 }}
          navbar={{
            width: "100%",
            breakpoint: "sm",
            collapsed: { desktop: true, mobile: !opened },
          }}
        >
          <AppShell.Header>
            <Group h="100%" px="md" justify="space-between">
              <Link to="home">
                <Logo />
              </Link>
              <Burger
                opened={drawerOpened}
                onClick={toggleDrawer}
                hiddenFrom="sm"
              />
              <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                <Group  gap={10} visibleFrom="sm">
                  <Link to="home">
                    <UnstyledButton>{i18n.t("menu.home")}</UnstyledButton>
                  </Link>
                  <HoverCard
                    width={600}
                    position="bottom"
                    radius="md"
                    shadow="md"
                    withinPortal
                  >
                    <HoverCard.Target>
                      <UnstyledButton>
                        <Center inline>
                          <Box component="span">
                        <Link to="features">
                            {i18n.t("menu.features")}
                        </Link>
                          </Box>
                          <IconChevronDown
                            style={{ width: rem(16), height: rem(16) }}
                            color={"#FF7F2A"}
                          />
                        </Center>
                      </UnstyledButton>
                    </HoverCard.Target>
                    <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                      <Group justify="space-between" px="md">
                        <Text fw={500}>Features</Text>
                      </Group>
                      <Divider my="sm" />
                      <SimpleGrid cols={2} spacing={0}>
                        {links}
                      </SimpleGrid>
                    </HoverCard.Dropdown>
                  </HoverCard>
                  <Link to="pricing">
                    <UnstyledButton>{i18n.t("menu.pricing")}</UnstyledButton>
                  </Link>
                </Group>
              </div>
              <Group visibleFrom="sm">
                <LanguagePicker />
              </Group>
            </Group>
          </AppShell.Header>

          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            title={<Logo />}
            hiddenFrom="sm"
            zIndex={1000000}>
            <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
              <Divider my="sm" />
              <Link to="home" onClick={closeDrawer}>
                <Text className={classes.link}>{i18n.t("menu.home")}</Text>
              </Link>
              <UnstyledButton className={classes.link} onClick={toggleLinks}>
                <Center inline>
                  <Box component="span" mr={5}>
                    {i18n.t("menu.features")}
                    <IconChevronDown
                    style={{ width: rem(16), height: rem(16) }}
                    color={"#FF7F2A"} />
                  </Box>
                </Center>
              </UnstyledButton>
              <Collapse in={linksOpened}>{links}</Collapse>
              <Link to={"pricing"} onClick={closeDrawer}>
                <Text className={classes.link}>{i18n.t("menu.pricing")}</Text>
              </Link>
              <Divider my="sm" />
              <LanguagePickerMobile />
            </ScrollArea>
          </Drawer>

          <AppShell.Main>
            <I18nextProvider i18n={i18n}>
              <BodyContent />
            </I18nextProvider>
          </AppShell.Main>
        </AppShell>
      </div>
    </LanguageProvider>
  );
}

export default App;
