import {
  Card,
  Avatar,
  Image,
  Text,
  Button,
  Divider,
  Modal,
  Space,
  Title,
  Anchor,
  Container,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ReactNode } from "react";
import classes from "./LegalModal.module.css";
import I18n from "../../i18n.js";
import parse from "html-react-parser";

interface LegalProps {
  id: string;
  close: () => void;
  opened: boolean;
}

export function LegalModal({
  id, close, opened
}: LegalProps) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Title>{I18n.t(`legal.${id}.heading`)}</Title>}
        fullScreen={isMobile}
      >
        <Space h="md" />
        <Space h="md" />
        <Divider />
        <Space h="md" />
        <Text>{parse(I18n.t(`legal.${id}.text`))}</Text>
        <Divider />
        <Button
          fullWidth
          className={classes.button}
          color={"#FF7F2A"}
          variant="outline"
          onClick={() => {
            close();
          }}
        >
          {I18n.t("general.close")}
        </Button>
      </Modal>
    </>
  );
}
